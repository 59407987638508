import Vue from 'vue'

import '@gameflexteam/flex-admin-vue/dist/flex-admin-vue.css'

import i18n from '@/plugins/i18n'
import apiConfig from './apiConfig'

import {
  CoreComponentsPlugin,
  DatatablePlugin,
  RecaptchaPlugin,
  ProgressPlugin,
  SwitchPlugin,
  UploadPlugin,
  UserEditInfoPlugin,
  JwtPlugin,
  NotificationPlugin,
  ApiPlugin,
  DateServicePlugin,
  MultiselectListboxPlugin
} from '@gameflexteam/flex-admin-vue'

Vue.use(CoreComponentsPlugin, {
  date: {
    dateFormat: i18n.t('config.dateFormat'),
    datetimeFormat: i18n.t('config.datetimeFormat')
  },
  validate: {
    messages: {
      confirmed: i18n.t('validation.confirmed'),
      date_after: i18n.t('validation.date_after'),
      email: i18n.t('validation.email'),
      integer: i18n.t('validation.integer'),
      length: i18n.t('validation.length'),
      max: i18n.t('validation.max'),
      min: i18n.t('validation.min'),
      password: i18n.t('validation.password'),
      required: i18n.t('validation.required')
    }
  },
  modal: {
    labels: {
      submit: `${i18n.t('labels.save')}`,
      cancel: `${i18n.t('labels.cancel')}`
    }
  }
})

Vue.use(UserEditInfoPlugin, {
  locale: i18n.locale,
  labels: {
    createdBy: i18n.t('labels.createdBy'),
    updatedBy: i18n.t('labels.updatedBy')
  }
})

Vue.use(DatatablePlugin, {
  locale: i18n.locale,
  labels: {
    create: i18n.t('labels.new'),
    delete: i18n.t('labels.delete'),
    edit: i18n.t('labels.edit'),
    actions: i18n.t('labels.actions'),
    cancel: i18n.t('labels.cancel')
  },
  messages: {
    errorOccurred: i18n.t('messages.errorOccurred'),
    deleteConfirmMessage: i18n.t('messages.deleteConfirmMessage')
  }
})

if (process.env.NODE_ENV === 'production') {
  Vue.use(RecaptchaPlugin, {
    // eslint-disable-next-line spellcheck/spell-checker
    siteKey: '6LeypjAhAAAAAGQLgVI_nhWjwGIss65ThrjlY6de',
    loaderOptions: {
      useRecaptchaNet: true,
      autoHideBadge: true
    }
  })
}

Vue.use(ProgressPlugin)

Vue.use(SwitchPlugin)

Vue.use(UploadPlugin, {
  labels: {
    selectedFileCount: i18n.t('labels.selectedFileCount')
  }
})

Vue.use(JwtPlugin)

Vue.use(NotificationPlugin)

Vue.use(ApiPlugin, apiConfig)

Vue.use(DateServicePlugin, {
  dateFormat: 'dd/MM/yyyy',
  datetimeFormat: 'dd/MM/yyyy HH:mm'
})

Vue.use(MultiselectListboxPlugin)
