import Vue from '../../main'

export default {
  state: {
    user: null,
    language: process.env.VUE_APP_I18N_LOCALE,
    isAuthenticated: false,
    authStatus: null,
    selectedProjectId: null,
    selectedCompanyId: null
  },
  getters: {
    user: state => state.user,
    isAuthenticated: state => state.isAuthenticated,
    selectedProjectId: state => state.selectedProjectId,
    selectedCompanyId: state => state.selectedCompanyId
  },
  mutations: {
    setAuth(state, payload) {
      const { user, token } = payload

      state.isAuthenticated = true
      state.user = user
      Vue.$flex.jwt.setToken(token)
    },
    setLogout(state) {
      state.isAuthenticated = false
      state.user = {}
      state.authStatus = null
      state.selectedProjectId = null
      state.selectedCompanyId = null
    },
    setProjectId(state, payload) {
      state.selectedProjectId = payload
    },
    setCompanyId(state, payload) {
      state.selectedCompanyId = payload
    },
    setAdminCompany(state, payload) {
      state.user.company = payload
    }
  },
  actions: {
    login: async ({ commit, state }, payload) => {
      const { email, password, recaptchaToken } = payload
      const data = await Vue.$http.post('/auth/login', {
        email,
        password,
        recaptchaToken
      })

      commit('setAuth', data)

      if (state.user.role === 'admin' && Vue.$route.query.returnUrl) {
        const { returnUrl } = Vue.$route.query
        const companies = await Vue.$http.get('/companies/withoutCount')

        const companySlug = returnUrl.split('/')[1]
        const projectSlug = returnUrl.split('/')[2]

        const company = companies.find(company => company.slug === companySlug) || companies[0]

        if (company) {
          state.selectedCompanyId = company?.id
          state.selectedProjectId =
          company.projects.find(project => project.slug === projectSlug)?.id ||
          JSON.stringify(company.projects.map(project => project.id))
        }
      } else {
        state.selectedProjectId = state.user.projects[0]?.id
        state.selectedCompanyId = state.user.company?.id
      }
    },
    activateUser: async (_context, payload) => {
      await Vue.$http.post('/auth/activate', payload)
    },
    fakeLogin: async ({ commit }) => {
      commit('setAuth', {
        user: {},
        // eslint-disable-next-line spellcheck/spell-checker
        token: 'ea2cb7cd-fd98-47a0-8540-05cce28bedbc'
      })
    },
    logout: async ({ commit, state }) => {
      if (state.isAuthenticated) {
        Vue.$flex.jwt.destroyAuthData()
        commit('setLogout')
      }

      if (Vue.$router.currentRoute.name !== 'login') {
        const returnUrl = `${window.location.pathname}${window.location.search}`

        Vue.$router.push({ name: 'login', query: { returnUrl } })
      }
    },
    setProject({ commit }, payload) {
      commit('setProjectId', payload)
    },
    setCompany({ commit }, payload) {
      commit('setCompanyId', payload)
    },
    updateAdminCompany({ commit }, payload) {
      commit('setAdminCompany', payload)
    }
  }
}
