import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import store from '../store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkExactActiveClass: 'active',
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash }
    }

    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  if (
    ![
      'pageNotFound',
      'dashboard',
      'login',
      'activateUser',
      'resetPassword',
      'forgotPassword'
    ].includes(to.name) &&
    store.getters.isAuthenticated
  ) {
    const companySlug = store.getters.user?.company?.slug
    const companyId = store.getters.user.companyId
    const projectSlugs = store.getters.user.projects.map(
      project => project.slug
    )

    if (!companyId) return next()

    if (
      companySlug !== to.params.companySlug
    ) {
      next({
        name: 'pageNotFound',
        params: {
          companySlug: companySlug,
          projectSlug: to.params.projectSlug
        }
      })
    }

    if (to.params.projectSlug) {
      projectSlugs.includes(to.params.projectSlug)
        ? next()
        : next({
          name: 'pageNotFound',
          params: {
            companySlug: companySlug,
            projectSlug: to.params.projectSlug
          }
        })
    } else {
      const projectIds = JSON.parse(store.getters.selectedProjectId)

      !!store.getters.user.projects.find(
        (project, index) => project.id !== projectIds[index]
      )
        ? next({
          name: 'pageNotFound',
          params: {
            companySlug: companySlug,
            projectSlug: to.params.projectSlug
          }
        })
        : next()
    }
  } else {
    next()
  }
})

router.beforeResolve((to, from, next) => {
  if (to.path === from.path) {
    window.location.reload()

    return
  }

  if (to.name) {
    // this.$flex.progress.start()
    const pageTitle = router.app.$options.i18n.t(`labels.${to.name}`)

    document.title = `${pageTitle} | ${process.env.VUE_APP_TITLE}`
  }

  const userLang = window.localStorage.getItem('user_lang')

  if (userLang) {
    router.app.$options.i18n.locale = userLang
  }

  next()
})

router.afterEach(() => {
  // this.$flex.progress.done()
})

export default router
