import store from '@/store'
import i18n from '../plugins/i18n'

const routes = [
  {
    path: `/${i18n.t('routes.user')}/`,
    component: () => import('@/layouts/user/index.vue'),
    children: [
      {
        path: `${i18n.t('routes.new')}`,
        name: 'activateUser',
        component: () => import('@/views/user/Activate.vue')
      },
      {
        path: `${i18n.t('routes.forgotPassword')}`,
        name: 'forgotPassword',
        component: () => import('@/views/user/ForgotPassword.vue')
      },
      {
        path: `${i18n.t('routes.login')}`,
        name: 'login',
        component: () => import('@/views/user/Login.vue')
      },
      {
        path: `${i18n.t('routes.resetPassword')}`,
        name: 'resetPassword',
        component: () => import('@/views/user/ResetPassword.vue')
      }
    ]
  },
  {
    path: '/',
    redirect: to => {
      if (store.getters.isAuthenticated) {
        return {
          name: 'pendingFeatures',
          params: {
            companySlug: store.getters.user?.company?.slug,
            projectSlug: store.getters.user?.projects[0]?.slug
          }
        }
      } else {
        return { name: 'login' }
      }
    },
    component: () => import('@/layouts/dashboard/index.vue'),
    children: [
      {
        path: `/:companySlug?/:projectSlug?/${i18n.t('routes.notFound')}`,
        name: 'pageNotFound',
        component: () => import('@/views/dashboard/NotFound.vue'),
      },
      {
        path: `/:companySlug?/:projectSlug?/${i18n.t('routes.dashboard')}`,
        name: 'dashboard',
        component: () => import('@/views/dashboard/Index.vue')
      },
      {
        path: `/:companySlug?/${i18n.t('routes.user')}/${i18n.t(
          'routes.settings'
        )}`,
        name: 'settings',
        component: () => import('@/views/user/settings/Index.vue')
      },
      {
        path: `${i18n.t('routes.management')}`,
        component: () => import('@/layouts/parentLayout.vue'),
        children: [
          {
            path: `/:companySlug?/:projectSlug?/${i18n.t('routes.users')}`,
            name: 'users',
            component: () => import('@/views/management/user/Index.vue')
          },
          {
            path: `/:companySlug?/:projectSlug?/${i18n.t('routes.deployments')}`,
            name: 'deployments',
            component: () => import('@/views/management/deployment/Index.vue')
          },
          {
            path: `${i18n.t('routes.companies')}`,
            name: 'companies',
            component: () => import('@/views/management/company/Index.vue')
          },
        ]
      },
      {
        path: i18n.t('routes.feature'),
        component: () => import('@/layouts/parentLayout.vue'),
        children: [
          {
            path: `/:companySlug?/:projectSlug?/${i18n.t(
              'routes.pendingFeatures'
            )}`,
            name: 'pendingFeatures',
            component: () => import('@/views/feature/Index.vue')
          },
          {
            path: `/:companySlug?/:projectSlug?/${i18n.t(
              'routes.completedFeatures'
            )}`,
            name: 'completedFeatures',
            component: () => import('@/views/feature/Index.vue')
          },
          {
            path: `/:companySlug?/:projectSlug?/${i18n.t(
              'routes.rejectedFeatures'
            )}`,
            name: 'rejectedFeatures',
            component: () => import('@/views/feature/Index.vue')
          }
        ]
      },
      {
        path: i18n.t('routes.bug'),
        component: () => import('@/layouts/parentLayout.vue'),
        children: [
          {
            path: `/:companySlug?/:projectSlug?/${i18n.t('routes.pendingBugs')}`,
            name: 'pendingBugs',
            component: () => import('@/views/bug/Index.vue')
          },
          {
            path: `/:companySlug?/:projectSlug?/${i18n.t(
              'routes.resolvedBugs'
            )}`,
            name: 'resolvedBugs',
            component: () => import('@/views/bug/Index.vue')
          },
          {
            path: `/:companySlug?/:projectSlug?/${i18n.t(
              'routes.rejectedBugs'
            )}`,
            name: 'rejectedBugs',
            component: () => import('@/views/bug/Index.vue')
          }
        ]
      },

      {
        path: i18n.t('routes.reports'),
        component: () => import('@/layouts/parentLayout.vue'),
        children: [
          {
            path: `/:companySlug?/:projectSlug?/${i18n.t('routes.featureReport')}`,
            name: 'featureReport',
            component: () => import('@/views/report/feature/Index.vue')
          }
        ]
      }
    ]
  }
]

export default routes
