import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import i18n from '@/plugins/i18n'
import '@/plugins/jquery'
import '@/plugins/flex-admin'

import 'bootstrap-table/dist/extensions/reorder-rows/bootstrap-table-reorder-rows.css'
import 'bootstrap-table/dist/extensions/reorder-rows/bootstrap-table-reorder-rows.js'

import 'tablednd'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import './assets/default.scss'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.config.productionTip = false

export default new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
