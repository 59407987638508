<template>
  <router-view />
</template>

<script>
// import { mapGetters } from 'vuex'

export default {
  computed: {
    // ...mapGetters(['language'])
  },

  created() {
    // this.$i18n.locale = this.language
  }
}
</script>
